import React, { useState } from 'react';
import styled from 'styled-components';
import { topicsImages, getTopicsKey } from '../../components/blogs/topicsImages';
import blogTopicImageTerrainChunk from '../../assets/images/blogs/terrainChunkLOD.webp';
import blogTopicImageTerrainChunkMin from '../../assets/images/blogs/terrainChunkLOD2.webp';
import blogTopicImageTerrainChunkMax from '../../assets/images/blogs/terrainChunkLOD1.webp';
import Modal from '../Modal';

const TweetContainer = styled.div`
  border: 1px solid #e1e8ed;
  padding: 16px;
  margin: 16px 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;  
  width: 100%;
  max-width: 800px;
  color: #fff;
  margin-top: 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #1e1e1e;
  margin-right: 8px;
`;

const TitleInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Titlename = styled.span`
  font-weight: bold;
  color: #fff;
`;

const Section = styled.span`
  color: #D3D3D3;
`;

const Content = styled.p`
  margin: 0;
  font-size: 16px;
  text-align: justify;
`;

const ImageContainer = styled.div`
  display: flex;
  gap: 8px; /* Space between images */
  margin-top: 12px;
  justify-content: center;
`;

const Image = styled.img`
  margin-top: 12px;
  width: 20%;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const BlogTopicTerrainOptimization: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const openModal = (imageSrc: string) => {
    setModalImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage('');
  };

  const avatarImageSource = topicsImages.terrain;
  const topicLabel = getTopicsKey("terrain");

  return (
    <TweetContainer>
      <Header>
        <Avatar src={avatarImageSource} alt="Avatar" />
        <TitleInfo>
          <Titlename>Terrain optimization</Titlename>
          <Section>#{topicLabel}</Section>
        </TitleInfo>
      </Header>
      <Content>
        During the terrain export process, the plugin provides the option to automatically generate different Level of Detail (LOD).
        This ensures that the terrain maintains performance and visual quality at various distances.
        Additionally, the terrain can be divided into smaller segments, which helps in optimizing the scene for better performance, especially in large and complex environments.
        This segmentation allows for more efficient rendering and resource management.
        <ImageContainer>
          <Image src={blogTopicImageTerrainChunk} alt="Blog Topic Image" onClick={() => openModal(blogTopicImageTerrainChunk)} />
          <Image src={blogTopicImageTerrainChunkMin} alt="Blog Topic Image 0" onClick={() => openModal(blogTopicImageTerrainChunkMin)} />
          <Image src={blogTopicImageTerrainChunkMax} alt="Blog Topic Image 2" onClick={() => openModal(blogTopicImageTerrainChunkMax)} />
        </ImageContainer>
      </Content>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <img src={modalImage} alt="Project Enlarged" style={{ width: '100%', height: '100%' }} />
      </Modal>
    </TweetContainer>
  );
};

export default BlogTopicTerrainOptimization;
