import React, { useState } from 'react';
import styled from 'styled-components';
import { topicsImages, getTopicsKey } from '../../components/blogs/topicsImages';
import blogTopicPPEngineFinalHDRP from '../../assets/images/blogs/ppengineFinal.webp';
import blogTopicUnityHDRPOpacity from '../../assets/images/blogs/unityHdrpOpacity.webp';
import blogTopicUnityHDRPOpacityDirt from '../../assets/images/blogs/unityHdrpOpacityDirt.webp';
import blogTopicPPEngineHDRPOpacity from '../../assets/images/blogs/ppengineHdrpOpacity.webp';
import blogTopicPPEngineHDRPOpacityDirt from '../../assets/images/blogs/ppengineHdrpOpacityDirt.webp';


import Modal from '../Modal';

const TweetContainer = styled.div`
  border: 1px solid #e1e8ed;
  padding: 16px;
  margin: 16px 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;  
  width: 100%;
  max-width: 800px;
  color: #fff;
  margin-top: 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #1e1e1e;
  margin-right: 8px;
`;

const TitleInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Titlename = styled.span`
  font-weight: bold;
  color: #fff;
`;

const Section = styled.span`
  color: #D3D3D3;
`;

const Content = styled.p`
  margin: 0;
  font-size: 16px;
  text-align: justify;
`;

const ImageContainer = styled.div`
  display: flex;
  gap: 8px; /* Space between images */
  margin-top: 12px;
  justify-content: center;
`;

const Image = styled.img`
  margin-top: 12px;
  width: 30%;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const BlogTopicHDRPOpacity: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const openModal = (imageSrc: string) => {
    setModalImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage('');
  };

  const avatarImageSource = topicsImages.shader;
  const topicLabel = getTopicsKey("shader");

  return (
    <TweetContainer>
      <Header>
        <Avatar src={avatarImageSource} alt="Avatar" />
        <TitleInfo>
          <Titlename>Unity HDRP opacity</Titlename>
          <Section>#{topicLabel}</Section>
        </TitleInfo>
      </Header>
      <Content>
        Integrating Unity HDRP into the Pixelpalm engine with a focus on reimplementing transparency
        <ImageContainer>
          <Image src={blogTopicPPEngineFinalHDRP} alt="Blog Topic Image 0" onClick={() => openModal(blogTopicPPEngineFinalHDRP)} />
        </ImageContainer>
      </Content>
      <Content>
        <br></br>
      </Content>
      <Content>
        Visualizing a dirty glass surface in a PBR engine is a real challenge. You need to accurately handle reflection, refraction and transparency.
        The detail map helps show the fine details of the dirt while alpha blending ensures that the clean and dirty areas blend correctly.
        The result is a realistic layered glass surface that reacts naturally to the surrounding lights.
      </Content>
      <Content>
        <br></br>
        On the left side you can see the original Unity scene while on the right side the reimplementation in the Pixel Palm Engine V2
      </Content>
      <ImageContainer>
        <Image src={blogTopicUnityHDRPOpacity} alt="Blog Topic Image 1" onClick={() => openModal(blogTopicUnityHDRPOpacity)} />
        <Image src={blogTopicPPEngineHDRPOpacity} alt="Blog Topic Image 2" onClick={() => openModal(blogTopicPPEngineHDRPOpacity)} />
      </ImageContainer>
      <ImageContainer>
        <Image src={blogTopicUnityHDRPOpacityDirt} alt="Blog Topic Image 3" onClick={() => openModal(blogTopicUnityHDRPOpacityDirt)} />
        <Image src={blogTopicPPEngineHDRPOpacityDirt} alt="Blog Topic Image 4" onClick={() => openModal(blogTopicPPEngineHDRPOpacityDirt)} />
      </ImageContainer>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <img src={modalImage} alt="Project Enlarged" style={{ width: '100%', height: '100%' }} />
      </Modal>
    </TweetContainer >
  );
};

export default BlogTopicHDRPOpacity;
