// src/components/Footer.tsx
import React from 'react';
import styled from 'styled-components';
import packageJson from '../../package.json';

const FooterContainer = styled.footer`
  padding: 20px;
  background-color: #1e1e1e;
  color: #ffffff;
  text-align: center;
`;

const FooterText = styled.p`
  color: #cccccc;
  margin: 0;
`;

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <FooterText>© 2019-2024 PixelNotes All rights reserved.</FooterText>
      <FooterText>Version {packageJson.version}</FooterText>
    </FooterContainer>
  );
};

export default Footer;
