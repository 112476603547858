// src/components/BlogSection.tsx
import React, { useRef } from 'react';
import styled from 'styled-components';
import blogs from './blogs'; // Import all blogs
import { groupBlogsByDate } from '../utils/groupBlogs'; // Import the grouping function
import * as mainTopic from '../components/blogs/topicsImages';

const images = [
  { src: mainTopic.topicsImages.general, label: '#general' },
  { src: mainTopic.topicsImages.web, label: '#web' },
  { src: mainTopic.topicsImages.physics, label: '#physics' },
  { src: mainTopic.topicsImages.animation, label: '#animation' },
  { src: mainTopic.topicsImages.terrain, label: '#terrain' },
  { src: mainTopic.topicsImages.shader, label: '#shader' },
];

const HEADER_HEIGHT = 80; // Adjust this value to match your header height

const BlogContainer = styled.section`
  padding: 40px;
  padding-top: ${HEADER_HEIGHT + 0}px; /* Add padding to account for fixed header */
  background: linear-gradient(to bottom, #1e1e1e, #555555);
  color: #ffffff;
  display: flex;
  justify-content: center;
`;

const BlogContentWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1000px; /* Adjust as needed */
  position: relative; /* Added to ensure the Menu can be positioned absolutely */
`;

const BlogContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  margin-left: 0px; /* Added margin to make space for the Menu */
`;

const BlogPost = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin-bottom: 40px;
`;

const SeparatorContainer = styled.div`
  width: 100%;
  position: relative;
  height: 1px; /* The height of the separator line */
`;

const SeparatorLine = styled.div`
  width: 80%;
  border-top: 1px solid #cccccc;
  position: absolute;
  top: -20px; /* Position the line at the top of the container */
  left: 50%;
  transform: translateX(-50%); /* Center the line horizontally */
`;

const Menu = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: ${HEADER_HEIGHT + 40}px; /* Adjust to start after the header */
  left: calc(50% - 500px - 150px); /* Adjust to position it to the left of BlogContent */
  background-color: rgba(30, 30, 30, 0.0); /* Set to semi-transparent */
  padding: 10px;
  border-left: 2px solid rgba(250, 250, 250, 0.5);
`;

const MenuItem = styled.a`
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 12px;
  cursor: pointer;
  transition: transform 0.3s;
  font-size: 13px;
  
  &:hover {
    transform: scale(1.1) translateX(12px);
    text-decoration: none; /* Remove underline */
  }
`;

const SubMenu = styled.div`
  margin-left: 20px; /* Indent months slightly to the right */
  display: flex;
  flex-direction: column; /* Ensure items are stacked vertically */
`;

const TitleContainer = styled.section`    
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;  
  padding: 0px;
  margin-top: 0px;  
  position: relative;
  top: 50px; /* Move the title down by 60px */
  background-color: transparent; /* Set to transparent */  
  width: 100%;
`;

const SubTitleContainer = styled.section`    
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;  
  padding: 0px;
  margin-top: 20px;  
  position: relative;
  top: 30px; /* Move the title down by 60px */
  background-color: transparent; /* Set to transparent */  
  width: 100%;
`;

const TopicsTitleContainer = styled.section`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;  
  padding: 0px;
  margin-top: 0px;  
  position: relative;
  top: 60px; /* Move the title down by 60px */
  background-color: transparent; /* Set to transparent */  
  width: 100%;
`;

const Avatar = styled.img`  
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #1e1e1e;
  margin: 0 8px;
  transition: transform 0.25s ease-in-out; /* Slower and smoother transition */
`;

const Label = styled.span`
  font-size: 16px;
  color: #D3D3D3;
  opacity: 0;
  transition: opacity 0.91s ease;
  position: relative;
  margin-top: 2px;
  white-space: nowrap;
`;

const ImageLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 16px; /* Space between pairs */
  position: relative;
  
  transition: transform 0.93s;

  &:hover ${Avatar} {
    transform: translateY(-10px); /* Slightly move the image up */
  }

  &:hover ${Label} {
    opacity: 1;
  }
`;

const BlogSection: React.FC = () => {
  const blogRefs = useRef<HTMLDivElement[]>([]);
  const groupedBlogs = groupBlogsByDate();

  const scrollToBlog = (index: number) => {
    blogRefs.current[index].scrollIntoView({ behavior: 'smooth' });
  };

  let blogIndex = 0;

  return (
    <BlogContainer>
      <BlogContentWrapper>
        <Menu>
          {Object.entries(groupedBlogs).map(([year, months]) => (
            <div key={year}>
              {Object.entries(months).map(([month, monthBlogs]) => (
                <div key={month}>
                  <div>
                    <h3>
                      {`${year} ${month}`}
                    </h3>
                  </div> {/* YYYY Month */}
                  <SubMenu>
                    {monthBlogs.map((blog, monthIndex) => {
                      const currentIndex = blogIndex++;
                      return (
                        <MenuItem key={`${year}-${month}-${monthIndex}`} onClick={() => scrollToBlog(currentIndex)}>
                          {blog.title}
                        </MenuItem>
                      );
                    })}
                  </SubMenu>
                </div>
              ))}
            </div>
          ))}
        </Menu>
        <BlogContent>
          <TitleContainer>
            <h2>MICROBLOG</h2>
          </TitleContainer>
          <SubTitleContainer>
            {images.map((image, index) => (
              <ImageLabelContainer key={index}>
                <Avatar src={image.src} alt={image.label} />
                <Label>{image.label}</Label>
              </ImageLabelContainer>
            ))}
          </SubTitleContainer>
          <TopicsTitleContainer>
            <h2>TOPICS</h2>
          </TopicsTitleContainer>
          {blogs.map((blog, index) => (
            <React.Fragment key={index}>
              <BlogPost ref={(el) => (blogRefs.current[index] = el!)}>
                <blog.component />
              </BlogPost>
              <SeparatorContainer>
                <SeparatorLine />
              </SeparatorContainer>
            </React.Fragment>
          ))}

        </BlogContent>
      </BlogContentWrapper>
    </BlogContainer>
  );
};

export default BlogSection;
