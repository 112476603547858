// src/components/projects/Project5.tsx
import React, { useState } from 'react';
import styled from 'styled-components';
import projectImage from '../../assets/images/renderXbox1080p.webp';
import imageRenderStates from '../../assets/images/renderStates.webp';
import engineStaticGifFrame from '../../assets/images/ppenginev2_static.webp';
import engineAnimatedGif from '../../assets/images/ppenginev2_anim.gif';
import engineShowCase0 from '../../assets/images/PixelPalm10.webp';
import Modal from '../Modal';

const HEADER_HEIGHT = 60;

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
`;

const ProjectTitle = styled.h2`
  padding-top: ${HEADER_HEIGHT + 20}px; /* Add padding to account for fixed header */
  color: #ffffff;
  text-align: center;
`;

const DescriptionTitle = styled.h2`
  padding-top: ${HEADER_HEIGHT + 0}px; /* Add padding to account for fixed header */
  color: #ffffff;
  text-align: center;
`;

const ProjectDescription = styled.p`
  padding-top: ${5}px; /* Add padding to account for fixed header */
  color: #cccccc;
  font-size: 1.2em;
  text-align: justify; /* Justify text alignment */
  margin: 0 1%;
  max-width: 1000px;
`;

const ProjectImage = styled.img`
  padding-top: ${20}px; /* Add padding to account for fixed header */
  width: 100%;
  max-width: 600px;
  max-height: 600px;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const SubTitle = styled.h2`
  padding-top: ${0}px; /* Add padding to account for fixed header */
  color: #ffffff;
  text-align: left;
  font-size: 1.1em;
`;

const BlogImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
`;

const BlogImage = styled.img`
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s;

  padding-top: ${40}px; /* Add padding to account for fixed header */

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

//user interface section
const UserInterfaceSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  max-width: 1000px;
  margin: 20px 0;
`;

const UserInterfaceDescription = styled.div`
  flex: 1;
  color: #cccccc;
  font-size: 1.2em;
  text-align: justify;
`;

const UserInterfaceImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UserInterfaceImage = styled.img`
  width: 90%;
  max-width: 100%;  
  max-height: 100%;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const ProjectCard = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: 250px; /* Adjust the width as needed */
  text-align: center;
  transition: transform 0.3s;

  &:hover {
    z-index: 1; /* Bring the hovered card to the front */
    transform: scale(1.025) translateY(5px); /* Scale and move down */
  }

  a {
    text-decoration: none; /* Remove link underline */
    color: inherit; /* Inherit color from parent */
  }

  &:hover img,
  &:hover p {
    transform: scale(1.05) translateY(5px); /* Scale and move down */
    transform-origin: center; /* Ensure scaling happens from the top */
  }

  img {
    width: 100%;
    max-width: 250px; /* Adjust the max-width as needed */
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s;
  }
`;
//

const Project5: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false);

  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const openModal = () => setIsModalOpen(true);
  // const closeModal = () => setIsModalOpen(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const openModal = (imageSrc: string) => {
    setModalImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage('');
  };

  return (
    <ProjectContainer>
      <ProjectTitle>Pixel Palm Engine V2</ProjectTitle>
      <ProjectDescription>A  Physically Based Rendering engine designed for cross-platform compatibility.</ProjectDescription>
      <ProjectImage src={projectImage} alt="Project 1" onClick={() => openModal(projectImage)} />
      {/* <Modal isOpen={isModalOpen} onClose={closeModal}>
        <img src={projectImage} alt="Project 1 Enlarged" style={{ width: '100%', height: '100%' }} />
      </Modal> */}
      <DescriptionTitle>Introduction</DescriptionTitle>
      <ProjectDescription>
        The engine operates on Windows and Xbox consoles including Xbox One and Xbox Series X where the latest features are tested and introduced.
        Older versions are also available for Linux and Mac although with some limitations compared to the Windows and Xbox versions.
        The core of the engine is built as a Universal Windows Platform (UWP) application using C# and MonoGame.
      </ProjectDescription>

      <BlogImagesContainer>
        <BlogImage
          src={isHovered ? engineAnimatedGif : engineStaticGifFrame}
          alt="Animated Blog"
          onClick={() => openModal(engineAnimatedGif)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
      </BlogImagesContainer>

      <DescriptionTitle>Rendering</DescriptionTitle>
      <ProjectDescription>
        The engine uses a deferred rendering pipeline with physically-based lighting. It includes its own content pipeline to manage materials, static and animated models and sound effects.
        From the start cross-platform development was a priority so the engine supports both Windows and Xbox and is designed as a portable class library.
        This design ensures that rendering functions can be easily called from different projects including those on Linux or Mac.
        <BlogImagesContainer>
          <BlogImage src={imageRenderStates} alt="Blog 3 Image 1" onClick={() => openModal(imageRenderStates)} />
        </BlogImagesContainer>
      </ProjectDescription>
      <DescriptionTitle>Main Features</DescriptionTitle>
      <ProjectDescription>
        <SubTitle>Advanced Physics Simulation:</SubTitle>
        The engine uses Bepuphysics for 3D simulations, ideal for complex scenes like 3D car simulations or animated characters with ragdoll physics.
        For simpler tasks such as animation controllers and basic collision checks the engine includes a built-in simple 3D physics engine.
        <SubTitle>Enhanced Scene Management:</SubTitle>
        The original version (V1.0) featured a separate WPF SDK application that allowed users to load models and textures or create and manipulate materials and lights
        and manage animations and physics entities.
        This made it much faster to create new scenes or update existing ones thanks to its export/import functionality.
        <SubTitle>Unity 3D Integration:</SubTitle>
        The new version (V2.0) introduced in late 2022 fully supports the Unity 3D engine and includes its own plugin.
        This means users no longer need to use the SDK. Instead they can create a new Unity scene set up lights, models, physics colliders, textures and export it.
        The engine can then consume the exported scene and set it up exactly as seen in Unity without needing any changes.
        <BlogImagesContainer>
          <BlogImage src={engineShowCase0} alt="EngineShowCase0" onClick={() => openModal(engineShowCase0)} />
        </BlogImagesContainer>
        <SubTitle>Web API and React UI:</SubTitle>
        To facilitate scene manipulation after deployment especially on Xbox with UWP V2.0 includes a built-in .NET Web API and a separate React UI.
        This setup allows for real-time updates to the scene while it is running on an Xbox overcoming the limitations of the V1 WPF SDK which did not support post-deployment updates.
        <SubTitle>Networking Support:</SubTitle>
        V2.0 also offers full networking support, including both client-server and peer-to-peer communication with NAT hole punching capabilities.
        This ensures robust and flexible networking options for various multiplayer scenarios.
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <img src={engineAnimatedGif} alt="Animated Blog Enlarged" style={{ width: '100%', height: '100%' }} />
        </Modal>
      </ProjectDescription>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <img src={modalImage} alt="Project Enlarged" style={{ width: '100%', height: '100%' }} />
      </Modal>
    </ProjectContainer>
  );
};

export default Project5;
