// src/components/projects/Project2.tsx
import React, { useState } from 'react';
import styled from 'styled-components';
import projectImage from '../../assets/images/project_polypeelrtemplate.webp'; // Adjust the path if necessary
import projectLoadFileImage from '../../assets/images/project_polypeelrloader.webp'; // Adjust the path if necessary
import blogImage3_1 from '../../assets/images/project_polypeelrmin.webp';
import blogImage3_2 from '../../assets/images/project_polypeelrmax.webp';
import fullscreenGif from '../../assets/images/polypeelr3d_loadinganim.gif';
import fullscreenStatic from '../../assets/images/polypeelr3d_loadinganim_static.webp';
import fullscreenPaintingGif from '../../assets/images/polypeelr3d_paintinganim.gif';
import fullscreenPaintingStatic from '../../assets/images/polypeelr3d_paintinganim_static.webp'; // Add a static frame image
// import engineAnimatedGif from '../../assets/images/ppenginev2_anim.gif'; // Adjust the path if necessary
import Modal from '../Modal';

const HEADER_HEIGHT = 60; // Adjust this value to match your header height

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
`;

const ProjectTitle = styled.h2`
  padding-top: ${HEADER_HEIGHT + 20}px; /* Add padding to account for fixed header */
  color: #ffffff;
  text-align: center;
`;

const DescriptionTitle = styled.h2`
  padding-top: ${HEADER_HEIGHT + 0}px; /* Add padding to account for fixed header */
  color: #ffffff;
  text-align: center;
`;

const ProjectDescription = styled.p`
  padding-top: ${5}px; /* Add padding to account for fixed header */
  color: #cccccc;
  font-size: 1.2em;
  text-align: justify; /* Justify text alignment */
  margin: 0 1%;
  max-width: 1000px;
`;

const ProjectImage = styled.img`
  padding-top: ${30}px; /* Add padding to account for fixed header */
  width: 100%;
  max-width: 800px;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const SubTitle = styled.h2`
  padding-top: ${0}px; /* Add padding to account for fixed header */
  color: #ffffff;
  text-align: left;
  font-size: 1.1em;
`;

const BlogImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const BlogImage = styled.img`
  width: 100%;
  max-width: 390px;
  height: 220px;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const FullScreenImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const FullScreenImage = styled.img`
  width: 100%;
  max-width: 800px;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

//user interface section
const UserInterfaceSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  max-width: 1000px;
  margin: 20px 0;
`;

const UserInterfaceDescription = styled.div`
  flex: 1.1;
  color: #cccccc;
  font-size: 1.0em;
  text-align: justify;
`;

const UserInterfaceImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;  
`;

const UserInterfaceImage = styled.img`
  width: 90%;
  height: 270px;
  max-width: 100%;  
  max-height: 100%;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;
//

const Project2: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false);

  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const openModal = () => setIsModalOpen(true);
  // const closeModal = () => setIsModalOpen(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const openModal = (imageSrc: string) => {
    setModalImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage('');
  };

  return (
    <ProjectContainer>
      <ProjectTitle>The Automatic UV Unwrapper</ProjectTitle>
      <ProjectDescription>PolyPeelr 3D is an advanced application designed to automate the process of generating UV maps for 3D models.</ProjectDescription>
      <ProjectImage src={projectImage} alt="Project 1" onClick={() => openModal(projectImage)} />
      <DescriptionTitle>Introduction</DescriptionTitle>
      <ProjectDescription>
        During the development of the PixelPalm Engine, I frequently use various models that often lack any UV maps.
        This requires me to manually unwrap these models to apply the appropriate textures. Over time, I began utilizing industry-popular software for this task, such as Substance Painter.
        Substance Painter features an automatic UV unwrapper function, which initially seemed quite efficient. However, I soon realized that it often created more work for me. The software tends to unwrap the model in a way that distorts the textures in many areas, requiring substantial manual adjustments to correct these distortions.
        This experience led me to the idea of creating my own UV unwrapper. My goal was to develop a tool focused on unwrapping models as quickly as possible, with minimal distortion.
        By focusing on these key aspects, I aimed to streamline my workflow and achieve a more efficient texturing process.
      </ProjectDescription>
      {/* <DescriptionTitle>UI</DescriptionTitle>
      <ProjectDescription>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras metus mi, suscipit vel vestibulum eget, sagittis eu lorem. Ut lobortis hendrerit lacus non mattis. Curabitur hendrerit dolor vitae lacus consequat, convallis elementum libero commodo. Vivamus vel vehicula sem. Aliquam eget massa quis nisi volutpat ornare ac ut mi. Nam convallis augue nec imperdiet tempus. Nunc tempus et enim eu blandit. Nullam in ligula at ligula placerat feugiat vel in augue. Integer dignissim varius neque eget porttitor. Ut in sapien dui. In eros nisl, laoreet id fermentum in, scelerisque mattis ligula. Aenean in dolor eu nisi hendrerit tempor. Morbi aliquet nisl a massa sodales, non feugiat nulla volutpat. Integer arcu diam, eleifend quis eros non, gravida vulputate lorem. Nullam condimentum sit amet augue vitae rutrum. 
      </ProjectDescription> */}

      {/* MAIN FEATURES */}
      <DescriptionTitle>Main Features</DescriptionTitle>
      <ProjectDescription>
        The main features of the application focus on simplifying and accelerating the texturing process for loaded models.
        During file processing the application not only unwraps the model but also automatically creates the Ambient Occlusion texture and generates a Color ID map.
        These two textures can be exported in the desired resolution along with the model making it easy for users to import them into any other software later.
        <SubTitle>File Import and Export:</SubTitle>

        <UserInterfaceSection>
          <UserInterfaceDescription>
            File loading is straightforward with a drag and drop function or through the classic menu.
            The application currently supports loading and processing FBX and OBJ files, although export is only possible in OBJ file format.

            Additionally, users can choose whether the software should completely regenerate the normal values or only calculate the missing ones.

          </UserInterfaceDescription>
          <UserInterfaceDescription>
            An important setting is whether the unwrapping process should only calculate missing UV values and those with very low pixel density
            or if it should completely recalculate the entire UV set thereby discarding all existing UV information from the loaded model.
          </UserInterfaceDescription>
          <UserInterfaceImageContainer>
            <UserInterfaceImage src={projectLoadFileImage} alt="Project 1" onClick={() => openModal(projectLoadFileImage)} />
          </UserInterfaceImageContainer>
        </UserInterfaceSection>

        <SubTitle>Texture sets:</SubTitle>
        The calculation of Color ID maps happens automatically. However, users have the option to generate a Color ID map from vertex colors if the file contains them.
        If not, the software will rearrange the UV set as optimally as possible considering the previously selected margin and generate a color ID map that can be recolored
        or redrawn at any time after loading.
        <SubTitle></SubTitle>
        Another significant feature of the application is its ability to automatically create an Ambient Occlusion Map during processing.
        This can be exported along with the Color ID map, allowing users to load these into any texturing software along with the exported model.

        <SubTitle>Responsive UI:</SubTitle>
        The UI automatically adjusts its layout, elements, and content to fit different screen sizes. In full-screen mode users can choose between different views such as split view
        (displaying the 3D model and 2D UV side by side) as well as 3D only or 2D only view. When exiting full-screen mode the UI automatically switches to allow only 3D or 2D view.
        <BlogImagesContainer>
          <BlogImage src={blogImage3_2} alt="Blog 3 Image 1" onClick={() => openModal(blogImage3_2)} />
          <BlogImage src={blogImage3_1} alt="Blog 3 Image 2" onClick={() => openModal(blogImage3_1)} />
        </BlogImagesContainer>
        Users can choose between perspective and orthographic camera views. If the user decides to use the camera settings stored in the model during loading
        those settings will be the default.
        <SubTitle></SubTitle>
        {/* <ProjectImage src={projectImage} alt="Project 1" onClick={() => openModal(projectImage)} />       */}

      </ProjectDescription>

      <FullScreenImagesContainer>
        <FullScreenImage
          src={isHovered ? fullscreenGif : fullscreenStatic}
          alt="Animated Blog"
          onClick={() => openModal(fullscreenGif)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
      </FullScreenImagesContainer>

      {/* ADVANCED FEATURES */}
      <DescriptionTitle>Advanced Features</DescriptionTitle>
      <ProjectDescription>
        <SubTitle>Background Color Change:</SubTitle>
        Users can change the background colors by selecting from preset options or generating a custom color using the color picker palette.
        This feature is available for both the 3D scene and the UV map. Upon export the modified background color will be saved.
        <SubTitle>Mesh Unwrapper:</SubTitle>
        If the user is not satisfied with the unwrapped result they can easily modify and regenerate the UV map through the "Unwrap Mesh" menu.
        The user can adjust the calculation sensitivity with the slider corresponding to the specific direction. This allows for generating entirely new UV maps in just a few steps.
        <SubTitle>Modify Color Maps:</SubTitle>
        If the created color map does not meet the expectations users can modify it in the "COLOR ID MAPS" menu.
        They can change the base color of each color map or use the brush tool to repaint them in real-time with the selected color.
        Upon export the modified texture will be saved.
      </ProjectDescription>

      <FullScreenImagesContainer>
        <FullScreenImage
          src={isHovered ? fullscreenPaintingGif : fullscreenPaintingStatic}
          alt="PolyPeelrPaintingAnimated"
          onClick={() => openModal(fullscreenPaintingGif)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
      </FullScreenImagesContainer>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <img src={modalImage} alt="Project Enlarged" style={{ width: '100%', height: '100%' }} />
      </Modal>
    </ProjectContainer>
  );
};

export default Project2;
