import React from 'react';
import styled from 'styled-components';
import { topicsImages, getTopicsKey } from '../../components/blogs/topicsImages';

const TweetContainer = styled.div`
  border: 1px solid #e1e8ed;
  padding: 16px;
  margin: 16px 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;  
  width: 100%;
  max-width: 800px;
  color: #fff;
  margin-top: 120px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #1e1e1e;
  margin-right: 8px;
`;

const TitleInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Titlename = styled.span`
  font-weight: bold;
  color: #fff;
`;

const Section = styled.span`
  color: #D3D3D3;
`;

const Content = styled.p`
  margin: 0;
  font-size: 16px;
  text-align: justify;
`;

const BlogNewYear: React.FC = () => {
      
    const avatarImageSource = topicsImages.general;
    const topicLabel = getTopicsKey("general");
  
  return (
    <TweetContainer>
      <Header>        
        <Avatar src={avatarImageSource} alt="Avatar" />
        <TitleInfo>
          <Titlename>New Year, New Domain</Titlename>
          <Section>#{topicLabel}</Section>
        </TitleInfo>
      </Header>
      <Content>
        Unfortunately, I forgot to renew the license for my old domain which means it's time for a fresh start..
      </Content>
      <Content>
        This new site is built from scratch using React, offering a more modern and dynamic experience compared to the simple Blogger page I had before.
        I'm excited about the upgrade but it will take a bit of time to transfer all the old posts to this new platform and to get everything perfectly aligned with the new design.
      </Content>     
    </TweetContainer>
  );
};

export default BlogNewYear;
