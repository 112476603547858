// src/components/projects/Project1.tsx
import React, { useState } from 'react';
import styled from 'styled-components';
import projectImage from '../../assets/images/project_svizz_ico3.webp'; // Adjust the path if necessary
import blogImage3_1 from '../../assets/images/project_svizz_onlyvertexpoints.webp';
import blogImage3_2 from '../../assets/images/project_svizz_onlyedge.webp';
import blogImage3_3 from '../../assets/images/project_svizz_pmi2obj.webp';
import Modal from '../Modal';

const HEADER_HEIGHT = 60; // Adjust this value to match your header height

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
`;

const ProjectTitle = styled.h2`
  padding-top: ${HEADER_HEIGHT + 20}px; /* Add padding to account for fixed header */
  color: #ffffff;
  text-align: center;
`;

const DescriptionTitle = styled.h2`
  padding-top: ${HEADER_HEIGHT + 0}px; /* Add padding to account for fixed header */
  color: #ffffff;
  text-align: center;
`;

const ProjectDescription = styled.p`
  padding-top: ${5}px; /* Add padding to account for fixed header */
  color: #cccccc;
  font-size: 1.2em;
  text-align: justify; /* Justify text alignment */
  margin: 0 1%;
  max-width: 1000px;
`;

const ProjectImage = styled.img`
  padding-top: ${20}px; /* Add padding to account for fixed header */
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const SubTitle = styled.h2`
  padding-top: ${0}px; /* Add padding to account for fixed header */
  color: #ffffff;
  text-align: left;
  font-size: 1.1em;
`;

const BlogImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
`;

const BlogImage = styled.img`
  width: 100%;
  max-width: 200px;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

//user interface section
const UserInterfaceSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  max-width: 1000px;
  margin: 20px 0;
`;

const UserInterfaceDescription = styled.div`
  flex: 1;
  color: #cccccc;
  font-size: 1.2em;
  text-align: justify;
`;

const UserInterfaceImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UserInterfaceImage = styled.img`
  width: 90%;
  max-width: 100%;  
  max-height: 100%;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;
//

const Project1: React.FC = () => {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const openModal = () => setIsModalOpen(true);
  // const closeModal = () => setIsModalOpen(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const openModal = (imageSrc: string) => {
    setModalImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage('');
  };

  return (
    <ProjectContainer>
      <ProjectTitle>Project Svizz</ProjectTitle>
      <ProjectDescription>A .NET WPF Application for loading and visualizing STEP Files with SharpDX</ProjectDescription>
      <ProjectImage src={projectImage} alt="Project 1" onClick={() => openModal(projectImage)} />
      {/* <Modal isOpen={isModalOpen} onClose={closeModal}>
        <img src={projectImage} alt="Project 1 Enlarged" style={{ width: '100%', height: '100%' }} />
      </Modal> */}
      <DescriptionTitle>Introduction</DescriptionTitle>
      <ProjectDescription>
        At a previous company, I had the opportunity to work with STEP files.
        The main goal was to create a web interface where users could upload their own models which would then display a 3D model along with the extracted graphical PMI information.
        However to achieve this I first needed to understand what a STEP file is, how it is structured and how it stores PMI information.
        Therefore in my spare time I started developing a .NET WPF desktop application capable of performing these tasks and this is how Project Svizz was born.
        {/* Project Svizz is an experimental .NET WPF application designed to load and visualize STEP (AP242) files, detect Product Manufacturing Information (PMI) and offer various visualization options. */}
      </ProjectDescription>
      <DescriptionTitle>UI</DescriptionTitle>
      <ProjectDescription>
        This application demonstrates how to load and visualize STEP (AP242) files with a focus on detecting and displaying PMI information. By providing various visualization states, users can inspect different aspects of the 3D models easily.
        The UI consist of essential components such as a file picker and a simple 3D viewer and buttons to visualize different aspects of the mesh and PMI data. Using SharpDX to render vertices, edges, faces and the full mesh with edge highlighting.
      </ProjectDescription>
      <DescriptionTitle>Advanced Features</DescriptionTitle>
      <ProjectDescription>
        <SubTitle>Import:</SubTitle>
        The application supports PMI visualization exclusively for AP242 file formats.
        After successful analysis it places the individual graphical PMI elements in the appropriate positions int the 3D space.
        If a PMI cannot be read correctly it attempts to place it at the origin.
        <SubTitle>Selected Elements Visualization:</SubTitle>
        The UI allows users to select which geometric elements should be displayed (only vertex points, only edges, only faces etc.)
        Additionally on UI the users can change the color of the selected elements with a simple color picker.
        <BlogImagesContainer>
          <BlogImage src={blogImage3_1} alt="Blog 3 Image 1" onClick={() => openModal(blogImage3_1)} />
          <BlogImage src={blogImage3_2} alt="Blog 3 Image 2" onClick={() => openModal(blogImage3_2)} />
          {/* <BlogImage src={blogImage3_3} alt="Blog 3 Image 3" onClick={() => openModal(blogImage3_3)} /> */}
        </BlogImagesContainer>
        <SubTitle>Convert STEP to OBJ:</SubTitle>
        <UserInterfaceSection>
          <UserInterfaceDescription>
            Users can export the loaded STEP file currently supporting only the .obj file format.
            The exported file contains the original model but also includes each extracted graphical PMI information as a new mesh.
            If the colors of the elements were previously changed the export will contains the new color information too.
          </UserInterfaceDescription>
          <UserInterfaceImageContainer>
            <UserInterfaceImage src={blogImage3_3} alt="Project 1" onClick={() => openModal(blogImage3_3)} />
          </UserInterfaceImageContainer>
        </UserInterfaceSection>
      </ProjectDescription>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <img src={modalImage} alt="Project Enlarged" style={{ width: '100%', height: '100%' }} />
      </Modal>
    </ProjectContainer>
  );
};

export default Project1;
