import React, { useState } from 'react';
import styled from 'styled-components';
import { topicsImages, getTopicsKey } from '../../components/blogs/topicsImages';
import Modal from '../Modal';

const TweetContainer = styled.div`
  border: 1px solid #e1e8ed;
  padding: 16px;
  margin: 16px 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;  
  width: 100%;
  max-width: 800px;
  color: #fff;
  margin-top: 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #1e1e1e;
  margin-right: 8px;
`;

const TitleInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Titlename = styled.span`
  font-weight: bold;
  color: #fff;
`;

const Section = styled.span`
  color: #D3D3D3;
`;

const Content = styled.p`
  margin: 0;
  font-size: 16px;
  text-align: justify;
`;

const ImageContainer = styled.div`
  display: flex;
  gap: 8px; /* Space between images */
  margin-top: 0px;
  justify-content: center;
  align-items: center; /* Center vertically */
`;

const Image = styled.img`
  margin-top: 12px;
  width: 20%;  
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const BlogTopicPhysics: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [isHoveredImage0, setIsHoveredImage0] = useState(false);
  const [isHoveredImage1, setIsHoveredImage1] = useState(false);

  const openModal = (imageSrc: string) => {
    setModalImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage('');
  };

  const avatarImageSource = topicsImages.physics;
  const topicLabel = getTopicsKey("physics");

  return (
    <TweetContainer>
      <Header>
        <Avatar src={avatarImageSource} alt="Avatar" />
        <TitleInfo>
          <Titlename>Simulate physics</Titlename>
          <Section>#{topicLabel}, #2019</Section>
        </TitleInfo>
      </Header>

      <Content>
        The engine also supports real-time physics simulation for which I integrated the
        <a href="https://github.com/bepu/bepuphysics2" target="_blank" rel="noopener noreferrer"> BepuPhysics library
        </a> into the Pixelpalm engine.
        This was actually one of the first major features I implemented back in 2019 when the engine was still functioning as a simple forward engine.
        Since then both the engine and the physics simulation support have improved significantly.
      </Content>
      <Content><br></br></Content>
      <Content>
        And now with just a single click all types of colliders supported by Unity can be fully exported to the Pixel Palm Engine V2.
        Not just simple shapes but even custom mesh colliders!
      </Content>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <img src={modalImage} alt="Project Enlarged" style={{ width: '100%', height: '100%' }} />
      </Modal>
    </TweetContainer>
  );
};

export default BlogTopicPhysics;
