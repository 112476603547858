// src/components/Header.tsx
import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/images/PPIcon.webp'; // Adjust the path if necessary

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #1e1e1e;
  color: #3e3e3e;
  z-index: 1000; /* Ensure header is on top */
`;

const LogoTaglineContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust the gap as needed */
  margin-bottom: 10px; /* Space between logo and navigation */
`;

const LogoImage = styled.img`
  height: 40px; /* Adjust the height as needed */
`;

const Tagline = styled.p`
  color: #ffffff;
  font-size: 2em;
  margin: 0; /* Remove default margin */
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 20px;

  a {
    color: #ffffff;
    text-decoration: none;
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.1);
      text-decoration: none; /* Remove underline */
    }

    &.active {
      text-decoration: underline;
    }
  }
`;

const Header: React.FC = () => {
  const location = useLocation();

  return (
    <HeaderContainer>
      <LogoTaglineContainer>
        <Tagline>Pixel</Tagline>
        <LogoImage src={logo} alt="Logo" />
        <Tagline>Notes</Tagline>
      </LogoTaglineContainer>
      <NavLinks>
        <Link to="/" className={location.pathname === '/' ? 'active' : ''}>Home</Link>
        <Link to="/projects" className={location.pathname === '/projects' ? 'active' : ''}>Projects</Link>
        <Link to="/blog" className={location.pathname === '/blog' ? 'active' : ''}>Blog</Link>
        {/* <Link to="/contact" className={location.pathname === '/contact' ? 'active' : ''}>Contact</Link> */}
      </NavLinks>
    </HeaderContainer>
  );
};

export default Header;
