import React, { useState } from 'react';
import styled from 'styled-components';
import { topicsImages, getTopicsKey } from '../../components/blogs/topicsImages';
import blogTopicImage0 from '../../assets/images/blogs/ppEngineSimplePhysics.gif';
import blogTopicImage0Static from '../../assets/images/blogs/ppEngineSimplePhysicsStatic.webp';
import Modal from '../Modal';

const TweetContainer = styled.div`
  border: 1px solid #e1e8ed;
  padding: 16px;
  margin: 16px 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;  
  width: 100%;
  max-width: 800px;
  color: #fff;
  margin-top: 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #1e1e1e;
  margin-right: 8px;
`;

const TitleInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Titlename = styled.span`
  font-weight: bold;
  color: #fff;
`;

const Section = styled.span`
  color: #D3D3D3;
`;

const Content = styled.p`
  margin: 0;
  font-size: 16px;
  text-align: justify;
`;

const ImageContainer = styled.div`
  display: flex;
  gap: 8px; /* Space between images */
  margin-top: 12px;
  justify-content: center;
`;

const Image = styled.img`
  margin-top: 12px;
  width: 20%;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const BlogTopicTemplate: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [isHoveredImage0, setIsHoveredImage0] = useState(false);

  const openModal = (imageSrc: string) => {
    setModalImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage('');
  };

  const avatarImageSource = topicsImages.physics;
  const topicLabel = getTopicsKey("physics");

  return (
    <TweetContainer>
      <Header>
        <Avatar src={avatarImageSource} alt="Avatar" />
        <TitleInfo>
          <Titlename>Introduce simple physics</Titlename>
          <Section>#{topicLabel}, #2022</Section>
        </TitleInfo>
      </Header>
      <Content>
        <i>This is an older topic from 2022.</i>
      </Content>
      <Content>
        The basic idea is to introduce a simple but fast triangle-based collision detection which can later be built upon with an animation controller that will work efficiently and seamlessly during networking.
        The implementation is straightforward involving a simple closest point calculation and adding some gravity.
      </Content>
      <ImageContainer>
        <Image src={isHoveredImage0 ? blogTopicImage0 : blogTopicImage0Static} alt="Blog Topic Image 0" onClick={() => openModal(blogTopicImage0)} onMouseEnter={() => setIsHoveredImage0(true)} onMouseLeave={() => setIsHoveredImage0(false)} />
      </ImageContainer>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <img src={modalImage} alt="Project Enlarged" style={{ width: '100%', height: '100%' }} />
      </Modal>
    </TweetContainer>
  );
};

export default BlogTopicTemplate;
