import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../Modal';
import { topicsImages, getTopicsKey } from '../../components/blogs/topicsImages';

const TweetContainer = styled.div`
  border: 1px solid #e1e8ed;
  padding: 16px;
  margin: 16px 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;  
  width: 100%;
  max-width: 800px;
  color: #fff;
  margin-top: 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #1e1e1e;
  margin-right: 8px;
`;

const TitleInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Titlename = styled.span`
  font-weight: bold;
  color: #fff;
`;

const Section = styled.span`
  color: #D3D3D3;
`;

const Content = styled.p`
  margin: 0;
  font-size: 16px;
  text-align: justify;
`;

const ImageContainer = styled.div`
  display: flex;
  gap: 8px; /* Space between images */
  margin-top: 12px;
  justify-content: center;
`;

const Image = styled.img`
  margin-top: 12px;
  width: 20%;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const BlogTopicWebPImages: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const avatarImageSource = topicsImages.general;
  const topicLabel = getTopicsKey("general");

  const openModal = (imageSrc: string) => {
    setModalImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage('');
  };

  return (
    <TweetContainer>
      <Header>
        <Avatar src={avatarImageSource} alt="Avatar" />
        <TitleInfo>
          <Titlename>Converting assets to WebP</Titlename>
          <Section>#{topicLabel}, #github</Section>
        </TitleInfo>
      </Header>
      <Content>
        Switching to WebP can greatly boost the website's performance by cutting down image file sizes and speeding up page load times.
        While there are a few things to consider the benefits usually outweigh the drawbacks especially with tools available that make the conversion process simple.
      </Content>
      <Content><br></br></Content>
      <Content>
        Since I've had to develop web APIs in the past with the primary task of image conversion so creating such a tool wasn't a significant challenge for me.
        Drawing from my previous solutions I was able to write a simple console application that efficiently converts existing images to WebP format.
      </Content>
      <ImageContainer>
        <div>
          {/* Other content of your component */}
          <a href="https://github.com/pxlnotes/playground/tree/main/src/dotnet/WebPGenerator" target="_blank" rel="noopener noreferrer">
            WebPGenerator
          </a>
        </div>
      </ImageContainer>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <img src={modalImage} alt="Project Enlarged" style={{ width: '100%', height: '100%' }} />
      </Modal>
    </TweetContainer>
  );
};

export default BlogTopicWebPImages;
