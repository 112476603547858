// src/components/Modal.tsx
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import CloseIcon from './CloseIcon'; // Import the close icon

// const ModalOverlay = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: rgba(0, 0, 0, 0.7);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   z-index: 1000;
// `;

const Overlay = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

// const ModalContent = styled.div`
//   background: white;
//   padding: 20px;
//   border-radius: 8px;
//   max-width: 90%;
//   max-height: 90%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

const ModalContent = styled.div`
  position: relative;
  max-width: 70%;
  max-height: 70%;
  margin: 0; /* Remove margin */
  padding: 0; /* Remove padding */
`;

// const CloseButton = styled.button`
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   background: darkred;
//   color: white;
//   border: none;
//   border-radius: 12px; /* Rounded rectangle */
//   padding: 5px 15px; /* Adjust padding as needed */
//   font-size: 16px;
//   cursor: pointer;
//   transition: background 0.3s;

//   &:hover {
//     background: #8b0000; /* Slightly darker red on hover */
//   }
// `;

const CloseButton = styled.button`
  position: absolute;
  top: -40px;
  right: -40px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  background-color: red;

  &:hover {
    background-color: red;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  // children: React.ReactNode;
  children: ReactNode;
}

// const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
//   if (!isOpen) return null;

//   return (
//     <ModalOverlay onClick={onClose}>
//       <ModalContent onClick={(e) => e.stopPropagation()}>
//         <CloseButton onClick={onClose}>Close</CloseButton>
//         {children}
//       </ModalContent>
//     </ModalOverlay>
//   );
// };

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  return (
    <Overlay isOpen={isOpen} onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        {children}
      </ModalContent>
    </Overlay>
  );
};
export default Modal;
