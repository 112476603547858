import React, { useState } from 'react';
import styled from 'styled-components';
import { topicsImages, getTopicsKey } from '../../components/blogs/topicsImages';
import ppEngineCar0Anim from '../../assets/images/blogs/202409/ppengine_physics_car0.gif';
import ppEngineCar0Static from '../../assets/images/blogs/202409/ppengine_physics_car0_static.webp';
import ppEngineCar1Anim from '../../assets/images/blogs/202409/ppengine_physics_car1.gif';
import ppEngineCar1Static from '../../assets/images/blogs/202409/ppengine_physics_car1_static.webp';
import ppEngineCar2Anim from '../../assets/images/blogs/202409/ppengine_physics_car2.gif';
import ppEngineCar2Static from '../../assets/images/blogs/202409/ppengine_physics_car2_static.webp';
import ppEngineCar3Anim from '../../assets/images/blogs/202409/ppengine_physics_car3.gif';
import ppEngineCar3Static from '../../assets/images/blogs/202409/ppengine_physics_car3_static.webp';
import ppEngineCar4Anim from '../../assets/images/blogs/202409/ppengine_physics_car4.gif';
import ppEngineCar4Static from '../../assets/images/blogs/202409/ppengine_physics_car4_static.webp';
import Modal from '../Modal';

const TweetContainer = styled.div`
  border: 1px solid #e1e8ed;
  padding: 16px;
  margin: 16px 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;  
  width: 100%;
  max-width: 800px;
  color: #fff;
  margin-top: 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #1e1e1e;
  margin-right: 8px;
`;

const TitleInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Titlename = styled.span`
  font-weight: bold;
  color: #fff;
`;

const Section = styled.span`
  color: #D3D3D3;
`;

const Content = styled.p`
  margin: 0;
  font-size: 16px;
  text-align: justify;
`;

const ImageContainer = styled.div`
  display: flex;
  gap: 8px; /* Space between images */
  margin-top: 0px;
  justify-content: center;
  align-items: center; /* Center vertically */
`;

const Image = styled.img`
  margin-top: 12px;
  width: 20%;  
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const BlogTopicCarController: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [isHoveredImage0, setIsHoveredImage0] = useState(false);
  const [isHoveredImage1, setIsHoveredImage1] = useState(false);
  const [isHoveredImage2, setIsHoveredImage2] = useState(false);

  const openModal = (imageSrc: string) => {
    setModalImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage('');
  };

  const avatarImageSource = topicsImages.physics;
  const topicLabel = getTopicsKey("physics");

  return (
    <TweetContainer>
      <Header>
        <Avatar src={avatarImageSource} alt="Avatar" />
        <TitleInfo>
          <Titlename>Physics-based car controller with suspension</Titlename>
          <Section>#{topicLabel}</Section>
        </TitleInfo>
      </Header>
      <Content><br></br></Content>
      <Content>
        <i>I believe this is a topic that doesn't require any additional commentary as the in-game videos below speak for themselves.</i>
      </Content>
      <Content><br></br></Content>
      <ImageContainer>
        <Image src={isHoveredImage0 ? ppEngineCar0Anim : ppEngineCar0Static} alt="Blog Topic Image 0" onClick={() => openModal(ppEngineCar0Anim)} onMouseEnter={() => setIsHoveredImage0(true)} onMouseLeave={() => setIsHoveredImage0(false)} />
        <Image src={isHoveredImage1 ? ppEngineCar1Anim : ppEngineCar1Static} alt="Blog Topic Image 1" onClick={() => openModal(ppEngineCar1Anim)} onMouseEnter={() => setIsHoveredImage1(true)} onMouseLeave={() => setIsHoveredImage1(false)} />
        <Image src={isHoveredImage2 ? ppEngineCar2Anim : ppEngineCar2Static} alt="Blog Topic Image 2" onClick={() => openModal(ppEngineCar2Anim)} onMouseEnter={() => setIsHoveredImage2(true)} onMouseLeave={() => setIsHoveredImage2(false)} />
      </ImageContainer>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <img src={modalImage} alt="Project Enlarged" style={{ width: '100%', height: '100%' }} />
      </Modal>
    </TweetContainer>
  );
};

export default BlogTopicCarController;
