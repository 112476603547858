// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import BlogSection from './components/BlogSection';
import Projects from './components/Projects';
import Project1 from './components/projects/Project1';
import Project2 from './components/projects/Project2';
import Project3 from './components/projects/Project3';
import Project4 from './components/projects/Project4';
import Project5 from './components/projects/Project5';
import Contact from './components/Contact';
import Footer from './components/Footer';

//background: linear-gradient(to right, darkgray, white, darkgray); /* Apply gradient here */
const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(to bottom, #1e1e1e, #555555);
`;

const MainContent = styled.main`
  flex: 1;
`;

const App: React.FC = () => {
  return (
    <Router>
      <AppContainer>
        <Header />
        <MainContent>
          <Routes>
            <Route path="/" element={<HeroSection />} />
            <Route path="/blog" element={<BlogSection />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/projects/svizz" element={<Project1 />} />
            <Route path="/projects/polypeelr" element={<Project2 />} />
            <Route path="/projects/animationplayer" element={<Project3 />} />
            <Route path="/projects/pbrviewer" element={<Project4 />} />
            <Route path="/projects/pixelpalmengine" element={<Project5 />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </MainContent>
        <Footer />
      </AppContainer>
    </Router>
  );
};

export default App;
