// src/components/Contact.tsx
import React, { useState } from 'react';
import styled from 'styled-components';

const HEADER_HEIGHT = 100; // Adjust this value to match your header height

const ContactContainer = styled.div`
  padding: 40px;
  background-color: transparent; /* Set to transparent */
  padding-top: ${HEADER_HEIGHT + 40}px; /* Add padding to account for fixed header */
  color: #ffffff; /* Change text color to white */
  text-align: center;
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
`;

const InputField = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #333; /* Dark background for contrast */
  color: #ffffff; /* White text color */
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #333; /* Dark background for contrast */
  color: #ffffff; /* White text color */
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: #2f2f2f; /* Custom background color */
  color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: darkgray; /* Custom hover color */
  }

  &:disabled {
    background-color: #2f2f2f; /* Custom disabled color */
    cursor: not-allowed; /* Change cursor style when disabled */
  }
`;

const Contact: React.FC = () => {
  const isDisabled = true;
  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission
    console.log(formData);
  };

  return (
    <ContactContainer>
      <h2></h2>
      <ContactForm onSubmit={handleSubmit}>
        <InputField type="text" name="name" placeholder="Your Name" value={formData.name} onChange={handleChange} required />
        <InputField type="email" name="email" placeholder="Your Email" value={formData.email} onChange={handleChange} required />
        <InputField type="text" name="subject" placeholder="Subject" value={formData.subject} onChange={handleChange} required />
        <TextArea name="message" placeholder="Your Message" value={formData.message} onChange={handleChange} required />
        <SubmitButton disabled={isDisabled} type="submit">Send Message</SubmitButton>
      </ContactForm>
      {/* <p>Or reach out via email: <a href="mailto:your-email@example.com">your-email@example.com</a></p> */}
      <p>Follow on social media:</p>
      <p>
        <a href="https://linkedin.com/in/yourprofile" target="_blank" rel="noopener noreferrer">LinkedIn</a> | 
        <a href="https://twitter.com/yourprofile" target="_blank" rel="noopener noreferrer"> Twitter</a> | 
        <a href="https://instagram.com/yourprofile" target="_blank" rel="noopener noreferrer"> Instagram</a>
      </p>
    </ContactContainer>
  );
};

export default Contact;
