import React, { useState } from 'react';
import styled from 'styled-components';
import { topicsImages, getTopicsKey } from '../../components/blogs/topicsImages';
import blogTopicPPEngineHalo1 from '../../assets/images/blogs/202409/ppengine_halo_1.webp';
import blogTopicPPEngineHalo82 from '../../assets/images/blogs/202409/ppengine_halo_82.webp';
import blogTopicPPEngineHalo294 from '../../assets/images/blogs/202409/ppengine_halo_294.webp';
import blogTopicUnityHalo1 from '../../assets/images/blogs/202409/unity_halo_1.webp';
import blogTopicUnityHalo82 from '../../assets/images/blogs/202409/unity_halo_82.webp';
import blogTopicUnityHalo294 from '../../assets/images/blogs/202409/unity_halo_294.webp';

import Modal from '../Modal';

const TweetContainer = styled.div`
  border: 1px solid #e1e8ed;
  padding: 16px;
  margin: 16px 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;  
  width: 100%;
  max-width: 800px;
  color: #fff;
  margin-top: 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #1e1e1e;
  margin-right: 8px;
`;

const TitleInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Titlename = styled.span`
  font-weight: bold;
  color: #fff;
`;

const Section = styled.span`
  color: #D3D3D3;
`;

const Content = styled.p`
  margin: 0;
  font-size: 16px;
  text-align: justify;
`;

const ImageContainer = styled.div`
  display: flex;
  gap: 8px; /* Space between images */
  margin-top: 12px;
  justify-content: center;
`;

const Image = styled.img`
  margin-top: 12px;
  width: 30%;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const BlogTopicPointLightHalo: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const openModal = (imageSrc: string) => {
    setModalImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage('');
  };

  const avatarImageSource = topicsImages.shader;
  const topicLabel = getTopicsKey("shader");

  return (
    <TweetContainer>
      <Header>
        <Avatar src={avatarImageSource} alt="Avatar" />
        <TitleInfo>
          <Titlename>Halo component</Titlename>
          <Section>#{topicLabel}</Section>
        </TitleInfo>
      </Header>
      <Content>
        Glowing areas around light sources

      </Content>
      <Content><br></br></Content>
      <Content>
        Well, Unity has this thing called a halo and since I’m adding more and more features to the Pixel Palm engine I thought it was time to introduce halos as well.
        The implementation is more or less complete but honestly I’m not entirely satisfied with the result.
        Adjusting the density isn’t straightforward at all.
      </Content>
      <Content>Instead of a smooth dense fog around the light source it looks more like noise...</Content>
      <Content><br></br></Content>
      <Content>As always on the left side you can see the original Unity scene while on the right side is the reimplementation.</Content>
      <Content>
        <ImageContainer>
          <Image src={blogTopicUnityHalo1} alt="Blog Topic Image 1" onClick={() => openModal(blogTopicUnityHalo1)} />
          <Image src={blogTopicPPEngineHalo1} alt="Blog Topic Image 2" onClick={() => openModal(blogTopicPPEngineHalo1)} />
        </ImageContainer>
        <ImageContainer>
          <Image src={blogTopicUnityHalo82} alt="Blog Topic Image 3" onClick={() => openModal(blogTopicUnityHalo82)} />
          <Image src={blogTopicPPEngineHalo82} alt="Blog Topic Image 4" onClick={() => openModal(blogTopicPPEngineHalo82)} />
        </ImageContainer>
        <ImageContainer>
          <Image src={blogTopicUnityHalo294} alt="Blog Topic Image 3" onClick={() => openModal(blogTopicUnityHalo294)} />
          <Image src={blogTopicPPEngineHalo294} alt="Blog Topic Image 4" onClick={() => openModal(blogTopicPPEngineHalo294)} />
        </ImageContainer>
      </Content>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <img src={modalImage} alt="Project Enlarged" style={{ width: '100%', height: '100%' }} />
      </Modal>
    </TweetContainer >
  );
};

export default BlogTopicPointLightHalo;
