// src/components/projects/Project3.tsx
import React, { useState } from 'react';
import styled from 'styled-components';
import projectImage from '../../assets/images/project_skinningdatagenerator_loaded.webp'; // Adjust the path if necessary
import menuImage from '../../assets/images/project_skinningdatagenerator_menu.webp'; // Adjust the path if necessary
import blogImage3_1 from '../../assets/images/project_skinningdatagenerator_anim0.webp';
import blogImage3_2 from '../../assets/images/project_skinningdatagenerator_anim1.webp';
import blogImage3_3 from '../../assets/images/project_skinningdatagenerator_anim2.webp';
import Modal from '../Modal';

const HEADER_HEIGHT = 60; // Adjust this value to match your header height

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
`;

const ProjectTitle = styled.h2`
  padding-top: ${HEADER_HEIGHT + 20}px; /* Add padding to account for fixed header */
  color: #ffffff;
  text-align: center;
`;

const DescriptionTitle = styled.h2`
  padding-top: ${HEADER_HEIGHT + 0}px; /* Add padding to account for fixed header */
  color: #ffffff;
  text-align: center;
`;

const SubTitle = styled.h2`
  padding-top: ${0}px; /* Add padding to account for fixed header */
  color: #ffffff;
  text-align: left;
  font-size: 1.1em;
`;

const ProjectDescription = styled.p`
  padding-top: ${5}px; /* Add padding to account for fixed header */
  color: #cccccc;
  font-size: 1.2em;
  text-align: justify; /* Justify text alignment */
  margin: 0 1%;
  max-width: 1000px;
`;

const ProjectImage = styled.img`
  padding-top: ${20}px; /* Add padding to account for fixed header */
  width: 100%;
  max-width: 800px;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const MenuImage = styled.img`
  padding-top: ${40}px; /* Add padding to account for fixed header */
  width: 100%;
  max-width: 200px;
    
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const BlogImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
`;

const BlogImage = styled.img`
  width: 100%;
  max-width: 800px;
  height: 200px;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

//user interface section
const UserInterfaceSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  max-width: 1000px;
  margin: 20px 0;
`;

const UserInterfaceDescription = styled.div`
  flex: 1;
  color: #cccccc;
  font-size: 1.2em;
  text-align: justify;
`;

const UserInterfaceImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UserInterfaceImage = styled.img`
  width: 90%;
  max-width: 100%;
  height: 450px;
  max-height: 100%;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;
//

const Project3: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');

  //const [isModalOpen, setIsModalOpen] = useState(false);

  // const openModal = () => setIsModalOpen(true);
  // const closeModal = () => setIsModalOpen(false);

  const openModal = (imageSrc: string) => {
    setModalImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage('');
  };

  return (
    <ProjectContainer>
      <ProjectTitle>SkinningData Generator</ProjectTitle>
      <ProjectDescription>.NET 6 WPF Application for FBX Animation Loading and Visualization</ProjectDescription>
      <ProjectImage src={projectImage} alt="Project 1" onClick={() => openModal(projectImage)} />
      {/* <Modal isOpen={isModalOpen} onClose={closeModal}>
        <img src={projectImage} alt="Project 1 Enlarged" style={{ width: '100%', height: '100%' }} />
      </Modal> */}
      <DescriptionTitle>Key Features</DescriptionTitle>
      <ProjectDescription>
        The core functionality of the application is to load FBX files (2013 or newer). Upon loading an animated file the application checks whether the file contains any skinning data.
        If no skinning data is detected the application simply loads and visualizes the static mesh.
        If skinning data is present the application parses this data to visualize the animated model.
      </ProjectDescription>
      <DescriptionTitle>Handling Animations</DescriptionTitle>
      <BlogImagesContainer>
        <BlogImage src={blogImage3_1} alt="Blog 3 Image 1" onClick={() => openModal(blogImage3_1)} />
        <BlogImage src={blogImage3_2} alt="Blog 3 Image 2" onClick={() => openModal(blogImage3_2)} />
        <BlogImage src={blogImage3_3} alt="Blog 3 Image 3" onClick={() => openModal(blogImage3_3)} />
      </BlogImagesContainer>
      <ProjectDescription>
        <SubTitle>Load Multiple Animations:</SubTitle>
        Users can load multiple FBX files with animations. The application checks if the models share the same bone structure. If they do it allows the user to
        load and visualize each animation and select which animation to play
        <SubTitle>Frame Rate Adjustments:</SubTitle>
        The application offers flexibility in animation playback with adjustable frame rates.
        Users can switch between 24, 30, 50 and 60 fps to see how the animation performs under different conditions.
        <SubTitle>Playback Speed Modification:</SubTitle>
        To help users identify issues in the animation the application allows modifying the playback speed.
        Users can choose between 1x, 0.5x or 0.25x speeds making it easier to check the animation details.
      </ProjectDescription>
      <DescriptionTitle>Advanced Features</DescriptionTitle>
      <ProjectDescription>
        <SubTitle>Loopable Animation Creation:</SubTitle>
        One of the standout features is the ability to convert non-loopable animations into loopable ones.
        Users can select the first and last frame of the animation using a simple slider.
        The application blends between these two frames, creating a seamless loop.
        <SubTitle>Animation Merging:</SubTitle>
        Another powerful feature is the ability to merge two animations.
        By selecting the starting frame from one animation and the ending frame from another users can create a new custom animation.
        This is done through a slider on the interface making the process straightforward and user-friendly.
        <SubTitle>Export and Import:</SubTitle>
        The application can export the current states of animations to an internal format, allowing them to be reloaded at any time for further modification.
      </ProjectDescription>
      {/* <DescriptionTitle>User Interface</DescriptionTitle>
      <ProjectDescription>The WPF application's UI is designed with XAML, ensuring a responsive and visually appealing layout. Key UI elements include:
      <SubTitle>File Loader:</SubTitle>
      A component to browse and select FBX files from the user's system.
      <SubTitle>Animation Viewer:</SubTitle>
      A viewport for visualizing static meshes and animations.
      <SubTitle>Control Panel:</SubTitle> 
      Contains controls for adjusting frame rates, playback speeds, and features for creating loopable animations and merging animations.
      </ProjectDescription>
      <MenuImage src={menuImage} alt="Project 1" onClick={() => openModal(menuImage)} />      
       <Modal isOpen={isModalOpen} onClose={closeModal}>
        <img src={modalImage} alt="Project Enlarged" style={{ width: '100%', height: '100%' }} />
      </Modal> */}
      <DescriptionTitle>User Interface</DescriptionTitle>
      <UserInterfaceSection>
        <UserInterfaceDescription>
          The WPF application's UI is designed with XAML, ensuring a responsive and visually appealing layout. Key UI elements include:
          <SubTitle>File Loader:</SubTitle>
          A component to browse and select FBX files from the user's system.
          <SubTitle>Animation Viewer:</SubTitle>
          A viewport for visualizing static meshes and animations.
          <SubTitle>Control Panel:</SubTitle>
          Contains controls for adjusting frame rates, playback speeds, and features for creating loopable animations and merging animations.
        </UserInterfaceDescription>
        <UserInterfaceImageContainer>
          <UserInterfaceImage src={menuImage} alt="Project 1" onClick={() => openModal(menuImage)} />
        </UserInterfaceImageContainer>
      </UserInterfaceSection>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <img src={modalImage} alt="Project Enlarged" style={{ width: '100%', height: '100%' }} />
      </Modal>
      {/* <DescriptionTitle>todo</DescriptionTitle>
      <ProjectDescription>todo</ProjectDescription> */}
    </ProjectContainer>
  );
};

export default Project3;
