import React, { useState } from 'react';
import styled from 'styled-components';
import { topicsImages, getTopicsKey } from '../../components/blogs/topicsImages';
import blogTopicImageEngineDemo0 from '../../assets/images/blogs/ppEngineTerrainDemo0.webp';
import blogTopicImageEngineDemo1 from '../../assets/images/blogs/ppEngineTerrainDemo1.webp';
import blogTopicImageUnity0 from '../../assets/images/blogs/unityTerrainDemo0.webp';
import blogTopicImageUnity1 from '../../assets/images/blogs/unityTerrainDemo1.webp';
import Modal from '../Modal';

const TweetContainer = styled.div`
  border: 1px solid #e1e8ed;
  padding: 16px;
  margin: 16px 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;  
  width: 100%;
  max-width: 800px;
  color: #fff;
  margin-top: 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #1e1e1e;
  margin-right: 8px;
`;

const TitleInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Titlename = styled.span`
  font-weight: bold;
  color: #fff;
`;

const Section = styled.span`
  color: #D3D3D3;
`;

const Content = styled.p`
  margin: 0;
  font-size: 16px;
  text-align: justify;
`;

const ImageContainer = styled.div`
  display: flex;
  gap: 8px; /* Space between images */
  margin-top: 12px;
  justify-content: center;
`;

const Image = styled.img`
  margin-top: 12px;
  width: 20%;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const BlogTopicTerrainDemoModels: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const openModal = (imageSrc: string) => {
    setModalImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage('');
  };

  const avatarImageSource = topicsImages.shader;
  const topicLabel = getTopicsKey("shader");

  return (
    <TweetContainer>
      <Header>
        <Avatar src={avatarImageSource} alt="Avatar" />
        <TitleInfo>
          <Titlename>Comparing scene chunks</Titlename>
          <Section>#{topicLabel}</Section>
        </TitleInfo>
      </Header>
      <Content>
        In this post, I will showcase a small slice of a scene to compare model positions, textures, lighting and shading between Unity and the Pixel Palm engine.
        First, you will see the Unity reference scene. In this scene all models are in the correct world space positions with HDRP textures and precise lighting settings.
        <ImageContainer>
          <Image src={blogTopicImageUnity0} alt="Blog Topic Image 0" onClick={() => openModal(blogTopicImageUnity0)} />
          <Image src={blogTopicImageUnity1} alt="Blog Topic Image 1" onClick={() => openModal(blogTopicImageUnity1)} />
        </ImageContainer>
      </Content>
      <Content>
        Below you will see the recreated scene from the Pixel Palm engine.
        This comparison helps ensure that models, textures, lighting and shading are consistent between Unity and the custom engine maintaining visual quality and accuracy.
      </Content>
      <ImageContainer>
        <Image src={blogTopicImageEngineDemo0} alt="Blog Topic Image 2" onClick={() => openModal(blogTopicImageEngineDemo0)} />
        <Image src={blogTopicImageEngineDemo1} alt="Blog Topic Image 3" onClick={() => openModal(blogTopicImageEngineDemo1)} />
      </ImageContainer>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <img src={modalImage} alt="Project Enlarged" style={{ width: '100%', height: '100%' }} />
      </Modal>
    </TweetContainer>
  );
};

export default BlogTopicTerrainDemoModels;
