import React, { useState } from 'react';
import styled from 'styled-components';
import { topicsImages, getTopicsKey } from '../../components/blogs/topicsImages';
import blogTopicPPEngineOpacityDirt10 from '../../assets/images/blogs/ppengineOpa1_0.webp';
import blogTopicPPEngineOpacityDirtxy from '../../assets/images/blogs/ppengineOpax_y.webp';
import blogTopicPPEngineOpacityDirtxx from '../../assets/images/blogs/ppengineOpax_x.webp';
import blogTopicPPEngineOpacityDirt06 from '../../assets/images/blogs/ppengineOpa0_6.webp';
import blogTopicPPEngineOpacityDirt00 from '../../assets/images/blogs/ppengineOpa0_0.webp';

import Modal from '../Modal';

const TweetContainer = styled.div`
  border: 1px solid #e1e8ed;
  padding: 16px;
  margin: 16px 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;  
  width: 100%;
  max-width: 800px;
  color: #fff;
  margin-top: 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #1e1e1e;
  margin-right: 8px;
`;

const TitleInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Titlename = styled.span`
  font-weight: bold;
  color: #fff;
`;

const Section = styled.span`
  color: #D3D3D3;
`;

const Content = styled.p`
  margin: 0;
  font-size: 16px;
  text-align: justify;
`;

const ImageContainer = styled.div`
  display: flex;
  gap: 8px; /* Space between images */
  margin-top: 12px;
  justify-content: center;
`;

const Image = styled.img`
  margin-top: 12px;
  width: 30%;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const BlogTopicHDRPOpacityPlayground: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const openModal = (imageSrc: string) => {
    setModalImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage('');
  };

  const avatarImageSource = topicsImages.shader;
  const topicLabel = getTopicsKey("shader");

  return (
    <TweetContainer>
      <Header>
        <Avatar src={avatarImageSource} alt="Avatar" />
        <TitleInfo>
          <Titlename>Dirty glass</Titlename>
          <Section>#{topicLabel}</Section>
        </TitleInfo>
      </Header>
      <Content>
        Experimenting with dirty glass effects:
      </Content>
      <Content>
        <br></br>
        No detail map (60% opacity):
      </Content>
      <ImageContainer>
        <Image src={blogTopicPPEngineOpacityDirtxx} alt="Blog Topic Image 2" onClick={() => openModal(blogTopicPPEngineOpacityDirtxx)} />
      </ImageContainer>
      <Content>
        <br></br>
        Detail map (95%-5% opacity):
      </Content>
      <ImageContainer>
        <Image src={blogTopicPPEngineOpacityDirt10} alt="Blog Topic Image 0" onClick={() => openModal(blogTopicPPEngineOpacityDirt10)} />
        <Image src={blogTopicPPEngineOpacityDirtxy} alt="Blog Topic Image 1" onClick={() => openModal(blogTopicPPEngineOpacityDirtxy)} />
        <Image src={blogTopicPPEngineOpacityDirt06} alt="Blog Topic Image 3" onClick={() => openModal(blogTopicPPEngineOpacityDirt06)} />
        <Image src={blogTopicPPEngineOpacityDirt00} alt="Blog Topic Image 4" onClick={() => openModal(blogTopicPPEngineOpacityDirt00)} />
      </ImageContainer>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <img src={modalImage} alt="Project Enlarged" style={{ width: '100%', height: '100%' }} />
      </Modal>
    </TweetContainer >
  );
};

export default BlogTopicHDRPOpacityPlayground;
