// src/utils/groupBlogs.ts
import { format } from 'date-fns';
import blogs from '../components/blogs';

export const groupBlogsByDate = () => {
  const groupedBlogs: { [year: string]: { [month: string]: typeof blogs } } = {};

  blogs.forEach((blog) => {
    const { year, month } = blog;
    const yearStr = year.toString();
    const monthStr = format(new Date(year, month - 1), 'MMMM'); // Format month to full month name

    if (!groupedBlogs[yearStr]) {
      groupedBlogs[yearStr] = {};
    }

    if (!groupedBlogs[yearStr][monthStr]) {
      groupedBlogs[yearStr][monthStr] = [];
    }

    groupedBlogs[yearStr][monthStr].push(blog);
  });

  return groupedBlogs;
};
