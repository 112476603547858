import React, { useState } from 'react';
import styled from 'styled-components';
import { topicsImages, getTopicsKey } from '../../components/blogs/topicsImages';
import blogTopicPPEngineEmissiveFinal0 from '../../assets/images/blogs/202409/ppengine_emissive_144.webp';
import blogTopicPPEngineEmissiveFinal1 from '../../assets/images/blogs/202409/ppengine_emissive_718.webp';
import blogTopicPPEngineEmissive143 from '../../assets/images/blogs/202409/ppengine_emissive_143.webp';
import blogTopicPPEngineEmissive717 from '../../assets/images/blogs/202409/ppengine_emissive_717.webp';
import blogTopicUnityEmissive143 from '../../assets/images/blogs/202409/unity_emissive_143.webp';
import blogTopicUnityEmissive717 from '../../assets/images/blogs/202409/unity_emissive_718.webp';


import Modal from '../Modal';

const TweetContainer = styled.div`
  border: 1px solid #e1e8ed;
  padding: 16px;
  margin: 16px 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;  
  width: 100%;
  max-width: 800px;
  color: #fff;
  margin-top: 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #1e1e1e;
  margin-right: 8px;
`;

const TitleInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Titlename = styled.span`
  font-weight: bold;
  color: #fff;
`;

const Section = styled.span`
  color: #D3D3D3;
`;

const Content = styled.p`
  margin: 0;
  font-size: 16px;
  text-align: justify;
`;

const ImageContainer = styled.div`
  display: flex;
  gap: 8px; /* Space between images */
  margin-top: 12px;
  justify-content: center;
`;

const Image = styled.img`
  margin-top: 12px;
  width: 30%;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const BlogTopicEmissiveMaterials: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const openModal = (imageSrc: string) => {
    setModalImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage('');
  };

  const avatarImageSource = topicsImages.shader;
  const topicLabel = getTopicsKey("shader");

  return (
    <TweetContainer>
      <Header>
        <Avatar src={avatarImageSource} alt="Avatar" />
        <TitleInfo>
          <Titlename>Emissive materials</Titlename>
          <Section>#{topicLabel}</Section>
        </TitleInfo>
      </Header>
      <Content>
        The Unity emissive materials were successfully integrated into the Pixelpalm engine.
        On the left you can see the original Unity scene and on the right the imported version.
        <ImageContainer>
          <Image src={blogTopicUnityEmissive143} alt="Blog Topic Image 1" onClick={() => openModal(blogTopicUnityEmissive143)} />
          <Image src={blogTopicPPEngineEmissive143} alt="Blog Topic Image 2" onClick={() => openModal(blogTopicPPEngineEmissive143)} />
        </ImageContainer>
        <ImageContainer>
          <Image src={blogTopicUnityEmissive717} alt="Blog Topic Image 1" onClick={() => openModal(blogTopicUnityEmissive717)} />
          <Image src={blogTopicPPEngineEmissive717} alt="Blog Topic Image 2" onClick={() => openModal(blogTopicPPEngineEmissive717)} />
        </ImageContainer>

      </Content>
      <Content>
        <br></br>
      </Content>
      <Content>
        But wait! I almost forgot to mention that the engine also supports Unity HDR colors!
      </Content>
      <Content>Below are the sample images:</Content>
      <ImageContainer>
        <Image src={blogTopicPPEngineEmissiveFinal0} alt="Blog Topic Image 0" onClick={() => openModal(blogTopicPPEngineEmissiveFinal0)} />
        <Image src={blogTopicPPEngineEmissiveFinal1} alt="Blog Topic Image 0" onClick={() => openModal(blogTopicPPEngineEmissiveFinal1)} />
      </ImageContainer>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <img src={modalImage} alt="Project Enlarged" style={{ width: '100%', height: '100%' }} />
      </Modal>
    </TweetContainer >
  );
};

export default BlogTopicEmissiveMaterials;
