// src/components/Projects.tsx
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import projectImage2 from '../assets/images/polypeelr1024.png';
// Project SkinningDataGenerator
import skdStaticGifFrame from '../assets/images/project_skinningdatagenerator_static.webp';
import skdAnimatedGif from '../assets/images/project_skinningdatagenerator_ico.gif';
// Project Svizz
import staticGifFrame from '../assets/images/project_svizz_ico_static.webp'; // Add a static frame image
import animatedGif from '../assets/images/project_svizz_ico.gif'; // Adjust the path if necessary
// Project ModelViewer
import pbrStaticGifFrame from '../assets/images/project_pbrmodelviewer_static.webp'; // Add a static frame image
import pbrAnimatedGif from '../assets/images/project_pbrmodelviewer_ico.gif'; // Adjust the path if necessary
// Project PixelPalm Engine V2
import projectImagePixelPalm from '../assets/images/splashscreen.webp';
// Project PolyPeelr 3D
import peelrStaticGifFrame from '../assets/images/project_polypeelr_static.webp';
import peelrAnimatedGif from '../assets/images/project_polypeelr_ico.gif';

const HEADER_HEIGHT = 80; // Adjust this value to match your header height

const ProjectsContainer = styled.div`
  padding: 40px;
  background-color: transparent; /* Set to transparent */
  padding-top: ${HEADER_HEIGHT + 20}px; /* Add padding to account for fixed header */
  color: #333;
  text-align: center;
`;

const CenteredProjectWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  width: 100%; /* Ensure it takes full width to center content */
`;

const ProjectsGrid = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 20px;
`;

const ProjectCard = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: 250px; /* Adjust the width as needed */
  text-align: center;
  transition: transform 0.3s;

  &:hover {
    z-index: 1; /* Bring the hovered card to the front */
    transform: scale(1.025) translateY(5px); /* Scale and move down */
  }

  a {
    text-decoration: none; /* Remove link underline */
    color: inherit; /* Inherit color from parent */
  }

  &:hover img,
  &:hover p {
    transform: scale(1.05) translateY(5px); /* Scale and move down */
    transform-origin: center; /* Ensure scaling happens from the top */
  }

  img {
    width: 100%;
    max-width: 250px; /* Adjust the max-width as needed */
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s;
  }
`;

const CenteredProjectCard = styled(ProjectCard)`
  width: 400px; /* Set width to 400px */
  
  img {
    max-width: 400px; /* Ensure image fits the new width */
  }
`;

const ProjectTitle = styled.p`
  color: #ffffff; /* Set the color to white */
  font-size: 1em;
  margin-top: 10px;
  transition: transform 0.3s;
`;

const ProjectsTitle = styled.h1`
  color: #ffffff; /* Set the color to white */
  margin-bottom: 50px; /* Adjust the margin as needed */
  font-family: 'Roboto mono'; /* Change the font family */
  font-size: 2.5em; /* Adjust the font size as needed */
  font-weight: bold; /* Adjust the font weight */
  text-transform: uppercase; /* Optional: transform text to uppercase */
  letter-spacing: 2px; /* Optional: add some letter spacing */
`;

const Projects: React.FC = () => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  return (
    <ProjectsContainer>
      <ProjectsTitle></ProjectsTitle>
      <CenteredProjectWrapper>
        <CenteredProjectCard>
          <Link to="/projects/pixelpalmengine">
            <img src={projectImagePixelPalm} alt="Project PP Engine V2" />
            <ProjectTitle>Pixel Palm Engine</ProjectTitle>
          </Link>
        </CenteredProjectCard>
      </CenteredProjectWrapper>
      <ProjectsGrid>
        <ProjectCard
          onMouseEnter={() => setHoveredIndex(0)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <Link to="/projects/svizz">
            <img src={hoveredIndex === 0 ? animatedGif : staticGifFrame} alt="Project 1" />
            <ProjectTitle>STEP file and PMI visualization</ProjectTitle>
          </Link>
        </ProjectCard>
        <ProjectCard
          onMouseEnter={() => setHoveredIndex(1)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <Link to="/projects/polypeelr">
            <img src={hoveredIndex === 1 ? peelrAnimatedGif : peelrStaticGifFrame} alt="Project 2" />
            <ProjectTitle>PolyPeelr 3D</ProjectTitle>
          </Link>
        </ProjectCard>
        <ProjectCard
          onMouseEnter={() => setHoveredIndex(2)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <Link to="/projects/animationplayer">
            <img src={hoveredIndex === 2 ? skdAnimatedGif : skdStaticGifFrame} alt="Project 3" />
            <ProjectTitle>Animation player</ProjectTitle>
          </Link>
        </ProjectCard>
        <ProjectCard
          onMouseEnter={() => setHoveredIndex(3)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <Link to="/projects/pbrviewer">
            <img src={hoveredIndex === 3 ? pbrAnimatedGif : pbrStaticGifFrame} alt="Project 4" />
            <ProjectTitle>PBR model viewer</ProjectTitle>
          </Link>
        </ProjectCard>
      </ProjectsGrid>
    </ProjectsContainer>
  );
};

export default Projects;
