// src/components/projects/Project4.tsx
import React, { useState } from 'react';
import styled from 'styled-components';
import projectImage from '../../assets/images/project_pbrmodelviewer2.webp';
import blogImage3_1 from '../../assets/images/project_pbrmodelviewer_lt.webp';
import blogImage3_2 from '../../assets/images/project_pbrmodelviewer_tg.webp';
import blogImage3_3 from '../../assets/images/project_pbrmodelviewer_ao.webp';
import Modal from '../Modal';

const HEADER_HEIGHT = 60;

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
`;

const ProjectTitle = styled.h2`
  padding-top: ${HEADER_HEIGHT + 20}px; /* Add padding to account for fixed header */
  color: #ffffff;
  text-align: center;
`;

const DescriptionTitle = styled.h2`
  padding-top: ${HEADER_HEIGHT + 0}px; /* Add padding to account for fixed header */
  color: #ffffff;
  text-align: center;
`;

const ProjectDescription = styled.p`
  padding-top: ${5}px; /* Add padding to account for fixed header */
  color: #cccccc;
  font-size: 1.2em;
  text-align: justify; /* Justify text alignment */
  margin: 0 1%;
  max-width: 1000px;
`;

const ProjectImage = styled.img`
  padding-top: ${20}px; /* Add padding to account for fixed header */
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const SubTitle = styled.h2`
  padding-top: ${0}px; /* Add padding to account for fixed header */
  color: #ffffff;
  text-align: left;
  font-size: 1.1em;
`;

const BlogImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
`;

const BlogImage = styled.img`
  width: 100%;
  max-width: 200px;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

//user interface section
const UserInterfaceSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  max-width: 1000px;
  margin: 20px 0;
`;

const UserInterfaceDescription = styled.div`
  flex: 1;
  color: #cccccc;
  font-size: 1.2em;
  text-align: justify;
`;

const UserInterfaceImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UserInterfaceImage = styled.img`
  width: 90%;
  max-width: 100%;  
  max-height: 100%;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;
//

const Project4: React.FC = () => {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const openModal = () => setIsModalOpen(true);
  // const closeModal = () => setIsModalOpen(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const openModal = (imageSrc: string) => {
    setModalImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage('');
  };

  return (
    <ProjectContainer>
      <ProjectTitle>PBR model viewer</ProjectTitle>
      <ProjectDescription>A 3D Model Viewer in UWP for Xbox</ProjectDescription>
      <ProjectImage src={projectImage} alt="Project 4" onClick={() => openModal(projectImage)} />
      <DescriptionTitle>History</DescriptionTitle>
      <ProjectDescription>
        This is a very old project of mine, essentially one of the first applications I wrote using UWP for Xbox One.
        The goal was simple I wanted to test the capabilities of the Xbox when running a 3D UWP application that utilizes various shaders for visualization.
        This led to the creation of this model viewer.
      </ProjectDescription>
      <DescriptionTitle>Framework and Tools</DescriptionTitle>
      <ProjectDescription>
        The PBR model viewer is developed using .NET UWP and MonoGame for rendering capabilities.
        This combination allows for a robust and visually appealing application that can handle complex 3D models and textures.
      </ProjectDescription>
      <DescriptionTitle>Main Features</DescriptionTitle>
      <ProjectDescription>
        <SubTitle>Drag and Drop Functionality:</SubTitle>
        The viewer supports drag and drop (only on Windows) for various model formats primarily FBX and OBJ files.
        Users can effortlessly load their models into the viewer which then processes and displays them in real-time.
        <SubTitle>Automatic Normal Calculation:</SubTitle>
        If the loaded model lacks normal maps the application automatically calculates them.
        Normals are essential for defining how light interacts with the surface of a model
        and their automatic generation ensures that even models without pre-defined normals can be accurately rendered.
        <BlogImagesContainer>
          <BlogImage src={blogImage3_1} alt="Blog 3 Image 1" onClick={() => openModal(blogImage3_1)} />
          <BlogImage src={blogImage3_2} alt="Blog 3 Image 2" onClick={() => openModal(blogImage3_2)} />
          {/* <BlogImage src={blogImage3_3} alt="Blog 3 Image 3" onClick={() => openModal(blogImage3_3)} /> */}
        </BlogImagesContainer>
        <SubTitle>Vertex Color Visualization:</SubTitle>
        In cases where UV mapping is not available the viewer uses vertex colors for visualization.
        This ensures that the model can still be displayed providing a fallback option that maintains a basic level of visual fidelity.
        <SubTitle>Texture Management:</SubTitle>
        Once a model is loaded users can drag and drop (only on windows) various textures such as albedo, normal, metallic, roughness and even ambient occlusion (AO) maps onto the viewer.
        <SubTitle>Real-Time Ambient Occlusion:</SubTitle>
        If the AO texture is missing the viewer can generate it in real-time. This feature can be toggled on or off giving control over the rendering process.
        <BlogImagesContainer>
          <BlogImage src={blogImage3_3} alt="Blog 3 Image 3" onClick={() => openModal(blogImage3_3)} />
        </BlogImagesContainer>
        <SubTitle>Interactive Model Manipulation:</SubTitle>
        Users can interact with the model using the left mouse button to rotate, middle mouse to zoom in and out and right mouse button to move the model within the scene,
        on Xbox the application using the controller's triggers.
        This interactive capability is crucial for thoroughly inspecting the model from various angles and distances.
        <SubTitle>Lighting Adjustments:</SubTitle>
        To verify the accuracy of the textures users can adjust the light position and color.
        This feature helps in identifying issues with the textures by simulating different lighting conditions ensuring that the model looks correct under various environments.

        {/* <UserInterfaceSection>
          <UserInterfaceDescription>
            Users can export the loaded STEP file currently supporting only the .obj file format.
            The exported file contains the original model but also includes each extracted graphical PMI information as a new mesh.
            If the colors of the elements were previously changed the export will contains the new color information too.
          </UserInterfaceDescription>          
          <UserInterfaceImageContainer>
            <UserInterfaceImage src={blogImage3_3} alt="Project 1" onClick={() => openModal(blogImage3_3)} />
          </UserInterfaceImageContainer>
        </UserInterfaceSection> */}
      </ProjectDescription>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <img src={modalImage} alt="Project Enlarged" style={{ width: '100%', height: '100%' }} />
      </Modal>
    </ProjectContainer>
  );
};

export default Project4;
