import React, { useState } from 'react';
import styled from 'styled-components';
import { topicsImages, getTopicsKey } from '../../components/blogs/topicsImages';
import blogTopicImage0 from '../../assets/images/blogs/AIStates.webp';
import blogTopicImage1 from '../../assets/images/blogs/ppengine_aistate_patrolstand.gif';
import blogTopicImage2 from '../../assets/images/blogs/ppengine_aistate_chasepatrol.gif';
import blogTopicImage1Static from '../../assets/images/blogs/ppengine_aistate_patrolstand_static.webp';
import blogTopicImage2Static from '../../assets/images/blogs/ppengine_aistate_chasepatrol_static.webp';
import Modal from '../Modal';

const TweetContainer = styled.div`
  border: 1px solid #e1e8ed;
  padding: 16px;
  margin: 16px 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;  
  width: 100%;
  max-width: 800px;
  color: #fff;
  margin-top: 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #1e1e1e;
  margin-right: 8px;
`;

const TitleInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Titlename = styled.span`
  font-weight: bold;
  color: #fff;
`;

const Section = styled.span`
  color: #D3D3D3;
`;

const Content = styled.p`
  margin: 0;
  font-size: 16px;
  text-align: justify;
`;

const ImageContainer = styled.div`
  display: flex;
  gap: 8px; /* Space between images */
  margin-top: 0px;
  justify-content: center;
  align-items: center; /* Center vertically */
`;

const Image = styled.img`
  margin-top: 12px;
  width: 20%;
  height: 60%;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const BlogTopicAiStatesBasics: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [isHoveredImage1, setIsHoveredImage1] = useState(false);
  const [isHoveredImage2, setIsHoveredImage2] = useState(false);

  const openModal = (imageSrc: string) => {
    setModalImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage('');
  };

  const avatarImageSource = topicsImages.general;
  const topicLabel = getTopicsKey("general");

  return (
    <TweetContainer>
      <Header>
        <Avatar src={avatarImageSource} alt="Avatar" />
        <TitleInfo>
          <Titlename>AI states</Titlename>
          <Section>#{topicLabel}, #2023</Section>
        </TitleInfo>
      </Header>
      <Content>
        <i>This is an older topic from 2023.</i>
      </Content>
      <Content>
        Built on the top of simple physics character controller AI State Machines enable dynamic and responsive NPCs.
        From guarding a specific area to chasing and attacking enemies they handle complex actions easily.
        An enemy guard its area, spots the opponent, chases them, attacks when close and returns to patrol state if the opponent escapes.
      </Content>
      <ImageContainer>
        <Image src={blogTopicImage0} alt="Blog Topic Image 0" onClick={() => openModal(blogTopicImage0)} />
        <Image src={isHoveredImage1 ? blogTopicImage1 : blogTopicImage1Static} alt="Blog Topic Image 1" onClick={() => openModal(blogTopicImage1)} onMouseEnter={() => setIsHoveredImage1(true)} onMouseLeave={() => setIsHoveredImage1(false)} />
        <Image src={isHoveredImage2 ? blogTopicImage2 : blogTopicImage2Static} alt="Blog Topic Image 2" onClick={() => openModal(blogTopicImage2)} onMouseEnter={() => setIsHoveredImage2(true)} onMouseLeave={() => setIsHoveredImage2(false)} />
      </ImageContainer>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <img src={modalImage} alt="Project Enlarged" style={{ width: '100%', height: '100%' }} />
      </Modal>
    </TweetContainer>
  );
};

export default BlogTopicAiStatesBasics;
