// src/components/HeroSection.tsx
import React, { useState } from 'react';
import styled from 'styled-components';
import heroImage from '../assets/images/splashscreen.webp'; // Use your new image path
// import Modal from './Modal';

const HEADER_HEIGHT = 60; // Adjust this value to match your header height

const HeroContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  
  padding-top: ${HEADER_HEIGHT + 20}px; /* Add padding to account for fixed header */
  background-color: transparent; /* Set to transparent */
  
`;

const HeroImage = styled.img`
  width: 100%;
  max-width: 800px;
  border-radius: 8px;
  transition: transform 0.3s;
  padding-top: ${10}px; /* Add padding to account for fixed header */
  &:hover {
    transform: scale(1.025);    
  }
`;

const HeroTitle = styled.h2`
  color: #ffffff;
  margin: 20px 0;
  font-size: 2em;
`;

const HeroText = styled.p`
  color: #cccccc;
  font-size: 1.2em;
  text-align: center;
  max-width: 800px; /* Adjust as needed */
`;

const HeroSection: React.FC = () => {
  //const [isModalOpen, setIsModalOpen] = useState(false);

  // const openModal = () => setIsModalOpen(true);
  // const closeModal = () => setIsModalOpen(false);

  return (
    <HeroContainer>
      <HeroTitle></HeroTitle>
      <HeroText>
        Welcome to my journey through the 3D world of .NET development.
      </HeroText>
      <HeroImage src={heroImage} alt="Hero" />
      {/* <HeroImage src={heroImage} alt="Hero" onClick={openModal} />       */}
      <HeroText>
        This microblog highlights various experimental projects, mainly focusing on 3D visualization within the .NET framework, along with some topics in TypeScript and WebGL.
      </HeroText>
      {/* <Modal isOpen={isModalOpen} onClose={closeModal}>
        <img src={heroImage} alt="Hero Enlarged" style={{ width: '100%', height: '100%' }} />
      </Modal> */}
    </HeroContainer>
  );
};

export default HeroSection;
