import React, { useState } from 'react';
import styled from 'styled-components';
import { topicsImages, getTopicsKey } from '../../components/blogs/topicsImages';
import blogTopicPPEnginePhysics51 from '../../assets/images/blogs/202409/ppengine_physics_51.webp';
import blogTopicPPEnginePhysics1130 from '../../assets/images/blogs/202409/ppengine_physics_1130.webp';
import blogTopicPPEnginePhysics1617 from '../../assets/images/blogs/202409/ppengine_physics_1617.webp';
import blogTopicPPEnginePhysics2025 from '../../assets/images/blogs/202409/ppengine_physics_2025.webp';
import blogTopicUnityPhysics51 from '../../assets/images/blogs/202409/unity_physics_51.webp';
import blogTopicUnityPhysics1130 from '../../assets/images/blogs/202409/unity_physics_1130.webp';
import blogTopicUnityPhysics1617 from '../../assets/images/blogs/202409/unity_physics_1617.webp';
import blogTopicUnityPhysics2025 from '../../assets/images/blogs/202409/unity_physics_2025.webp';


import Modal from '../Modal';

const TweetContainer = styled.div`
  border: 1px solid #e1e8ed;
  padding: 16px;
  margin: 16px 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;  
  width: 100%;
  max-width: 800px;
  color: #fff;
  margin-top: 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #1e1e1e;
  margin-right: 8px;
`;

const TitleInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Titlename = styled.span`
  font-weight: bold;
  color: #fff;
`;

const Section = styled.span`
  color: #D3D3D3;
`;

const Content = styled.p`
  margin: 0;
  font-size: 16px;
  text-align: justify;
`;

const ImageContainer = styled.div`
  display: flex;
  gap: 8px; /* Space between images */
  margin-top: 12px;
  justify-content: center;
`;

const Image = styled.img`
  margin-top: 12px;
  width: 30%;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const BlogTopicPhysicsMeshColliders: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const openModal = (imageSrc: string) => {
    setModalImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage('');
  };

  const avatarImageSource = topicsImages.physics;
  const topicLabel = getTopicsKey("physics");

  return (
    <TweetContainer>
      <Header>
        <Avatar src={avatarImageSource} alt="Avatar" />
        <TitleInfo>
          <Titlename>Custom mesh colliders</Titlename>
          <Section>#{topicLabel}</Section>
        </TitleInfo>
      </Header>
      <Content>
        Exporting and importing simple physics bodies like a cube or a sphere isn’t much of a challenge and even with more complex shapes it’s straightforward.
        You just need to collect the vertex points and their world space positions to ensure they’re in the correct place after import.
        I put together a simple scene where I created a custom collider for a buggy-like car to test the export-import functionality.
      </Content>
      <Content>
        <br></br>
      </Content>
      <Content>
        <i>
          The main difference this time is that I’m only attaching the visualization of the physics colliders to the albedo layer,
          since I’m not really interested in them in the final scene anyway.
        </i>
      </Content>
      <Content>
        <br></br>
      </Content>
      <Content>
        As always on the left side you can see the custom mesh collider from the Unity scene while on the right side is the imported collider.
      </Content>
      <Content>
        <br></br>
      </Content>
      <ImageContainer>
        <Image src={blogTopicUnityPhysics51} alt="Blog Topic Image 0" onClick={() => openModal(blogTopicUnityPhysics51)} />
        <Image src={blogTopicPPEnginePhysics51} alt="Blog Topic Image 1" onClick={() => openModal(blogTopicPPEnginePhysics51)} />
      </ImageContainer>
      <ImageContainer>
        <Image src={blogTopicUnityPhysics1130} alt="Blog Topic Image 2" onClick={() => openModal(blogTopicUnityPhysics1130)} />
        <Image src={blogTopicPPEnginePhysics1130} alt="Blog Topic Image 3" onClick={() => openModal(blogTopicPPEnginePhysics1130)} />
      </ImageContainer>
      <ImageContainer>
        <Image src={blogTopicUnityPhysics1617} alt="Blog Topic Image 4" onClick={() => openModal(blogTopicUnityPhysics1617)} />
        <Image src={blogTopicPPEnginePhysics1617} alt="Blog Topic Image 5" onClick={() => openModal(blogTopicPPEnginePhysics1617)} />
      </ImageContainer>
      <ImageContainer>
        <Image src={blogTopicUnityPhysics2025} alt="Blog Topic Image 4" onClick={() => openModal(blogTopicUnityPhysics2025)} />
        <Image src={blogTopicPPEnginePhysics2025} alt="Blog Topic Image 5" onClick={() => openModal(blogTopicPPEnginePhysics2025)} />
      </ImageContainer>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <img src={modalImage} alt="Project Enlarged" style={{ width: '100%', height: '100%' }} />
      </Modal>
    </TweetContainer >
  );
};

export default BlogTopicPhysicsMeshColliders;
