// src/assets/images/index.ts
import imageSectionIconWeb from '../../assets/images/topics/iconweb.webp';
import imageSectionIconPhysics from '../../assets/images/topics/iconphysics.webp';
import imageSectionIconAnimation from '../../assets/images/topics/iconanim.webp';
import imageSectionIconGeneral from '../../assets/images/topics/icongeneral.webp';
import imageSectionIconTerrain from '../../assets/images/topics/iconterrain.webp';
import imageSectionIconShader from '../../assets/images/topics/iconshader.webp';

export const topicsImages = {
  general: imageSectionIconGeneral,
  web: imageSectionIconWeb,
  physics: imageSectionIconPhysics,
  animation: imageSectionIconAnimation,
  terrain: imageSectionIconTerrain,
  shader: imageSectionIconShader
};

export function getTopicsKey(key: string) {
  const keys = Object.keys(topicsImages);
  return keys.find(k => k === key);
}
