// src/components/blogs/index.ts
import BlogTopicNewYear from './BlogTopicNewYear';
import BlogTopicNewDesign from './BlogTopicNewDesign';
import BlogTopicTerrainIntro from './BlogTopicTerrainIntro';
import BlogTopicTerrainDemoModels from './BlogTopicTerrainDemoModels';
import BlogTopicTerrainOptimization from './BlogTopicTerrainOptimization';
import BlogTopicSimplePhysics from './BlogTopicSimplePhysics';
import BlogTopicAiStatesBasics from './BlogTopicAiStatesBasics';
import BlogTopicAiStatesAnimation from './BlogTopicAiStatesAnimation';
import BlogTopicWebPImages from './BlogTopicWebPImages';
import BlogTopicPixelizer from './BlogTopicPixelizer';
import BlogTopicHDRPOpacity from './BlogTopicHDRPOpacity';
import BlogTopicHDRPOpacityPlayground from './BlogTopicHDRPOpacityPlayground';
import BlogTopicEmissive from './BlogTopicEmissiveMaterials';
import BlogTopicPointLightHalo from './BlogTopicPointLightHalo';
import BlogTopicPhysics from './BlogTopicPhysics';
import BlogTopicMeshColliders from './BlogTopicPhysicsMeshColliders';
import BlogTopicCarController from './BlogTopicCarController';
// import Template from './BlogTopicTemplate';

const blogs = [
  { component: BlogTopicNewYear, title: 'New microblog', year: 2024, month: 1 },
  { component: BlogTopicTerrainIntro, title: 'Terrain generation', year: 2024, month: 6 },
  { component: BlogTopicTerrainOptimization, title: 'Terrain optimization', year: 2024, month: 7 },
  { component: BlogTopicTerrainDemoModels, title: 'Terrain model', year: 2024, month: 7 },
  { component: BlogTopicNewDesign, title: 'New blog design', year: 2024, month: 7 },
  { component: BlogTopicSimplePhysics, title: 'Simple physics', year: 2024, month: 7 },
  { component: BlogTopicAiStatesBasics, title: 'AI states', year: 2024, month: 7 },
  { component: BlogTopicAiStatesAnimation, title: 'AI with animations', year: 2024, month: 7 },
  { component: BlogTopicWebPImages, title: 'Site optimization', year: 2024, month: 8 },
  { component: BlogTopicPixelizer, title: 'Pixelizer', year: 2024, month: 8 },
  { component: BlogTopicHDRPOpacity, title: 'Unity HDRP opacity', year: 2024, month: 8 },
  { component: BlogTopicHDRPOpacityPlayground, title: 'Dirty glass experiments', year: 2024, month: 8 },
  { component: BlogTopicEmissive, title: 'Emissive materials', year: 2024, month: 9 },
  { component: BlogTopicPointLightHalo, title: 'Halo', year: 2024, month: 9 },
  { component: BlogTopicPhysics, title: 'Simulate physics', year: 2024, month: 9 },
  { component: BlogTopicMeshColliders, title: 'Custom colliders', year: 2024, month: 9 },
  { component: BlogTopicCarController, title: 'Car controller', year: 2024, month: 9 },
  // { component: Template, title: 'TEMP TOPIC', year: 2000, month: 1 },    
];

export default blogs;
